import { NavigateFunction } from "react-router";
import { submitWeighment } from "../firebase/FirebaseFirestoreRestService";
import { LocalStateAction } from "../types";

export const handleSubmitButton = async (
  Navigate: NavigateFunction,
  otp: number,
  weighment: number,
  setSubmitting: LocalStateAction<boolean>
) => {
  setSubmitting(true);
  try {
    await submitWeighment(otp, weighment);

    Navigate("/successpage");
  } catch (error) {}
  setSubmitting(false);
};

export const handleHomeButton = async (Navigate: NavigateFunction) => {
  try {
    Navigate("/");
  } catch (error) {}
};
