const BASE_URL = process.env.REACT_APP_CLOUD_FIRESTORE_FUNCTION_API_URL;
console.log(BASE_URL);

export const submitWeighment = async (otp: number, weighment: number) => {
  try {
    const response = await fetch(`${BASE_URL}/weighment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ otp, weighment }),
    });

    if (response.status !== 200) {
      const errorMessage = await response.json();
      const error = { message: errorMessage.error };
      throw error;
    }

    return response.json();
  } catch (error) {
    alert((error as Error).message);
    throw error;
  }
};
