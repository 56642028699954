import { createBrowserRouter, RouterProvider } from "react-router-dom";
import SuccessPage from "./pages/SuccessPage";
import WeightInputScreen from "./components/WeightInputScreen";

function Router() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <WeightInputScreen />,
    },
    {
      path: "/successpage",
      element: <SuccessPage />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default Router;
