import { FormEvent, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { handleSubmitButton } from "../helpers";
import { useNavigate } from "react-router-dom";

const WeightInputScreen = () => {
  const [otp, setOtp] = useState<string>("");
  const [weight, setWeight] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);
  const Navigate = useNavigate();

  function handleSubmitButtonClick(e: FormEvent) {
    e.preventDefault();
    if (otp && weight) {
      handleSubmitButton(
        Navigate,
        parseFloat(otp),
        parseFloat(weight),
        setSubmitting
      );
    }
  }

  return (
    <form onSubmit={handleSubmitButtonClick} className="full-width-height">
      <Box className="center-everything full-width-height">
        <Typography
          variant="h2"
          component="h2"
          style={{ marginBottom: "20px", marginTop: 0, paddingTop: 0 }}
        >
          Weight Input
        </Typography>
        <TextField
          label="Weight"
          variant="outlined"
          required
          value={weight}
          onChange={(e) => {
            setWeight(e.target.value);
          }}
          style={{ margin: "10px" }}
        />
        <TextField
          label="OTP"
          variant="outlined"
          required
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
          }}
          style={{ margin: "10px" }}
        />
        <Button
          variant="contained"
          type="submit"
          disabled={submitting}
          style={{ margin: "10px", width: "195px" }}
        >
          {submitting ? "Submitting" : "Submit"}
          {submitting ? (
            <CircularProgress color="inherit" size={16} sx={{ ml: 2 }} />
          ) : null}
        </Button>
      </Box>
    </form>
  );
};

export default WeightInputScreen;
